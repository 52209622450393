import {WhitelabelEvent, WhitelabelEventWithPayload} from './Events';
import {LocalStorageWrapper} from "../LocalStorageWrapper";

export type Subscriber = (event: WhitelabelEvent) => void;
export type SubscriberWithId = {
    id: string;
    callback: Subscriber;
}

export class EventBus {
    private static subscribersWithIds: { [type: string] : Array<SubscriberWithId> };
    private static isDebug: boolean;

    private constructor() {
        EventBus.subscribersWithIds = {};
        EventBus.isDebug = new LocalStorageWrapper().getValue('debugEvents') === 'true';
    }

    /** @deprecated Use subscribeWithId() */
    subscribe(type: string, subscriber: Subscriber) {
        const uuid: string = `${Date.now()}-${Math.random().toString(36).substring(7)}`;
        this.subscribeWithId(type, {id: uuid, callback: subscriber});
        return this;
    }

    subscribeWithId(type: string, subscriber: SubscriberWithId) {
        const subscribers: SubscriberWithId[] = EventBus.subscribersWithIds[type] || [];
        if (subscribers.some((s: SubscriberWithId) => s.id === subscriber.id)) {
            throw new Error(`Subscriber with id ${subscriber.id} already exists for event type ${type}`);
        }
        EventBus.subscribersWithIds[type] = [...subscribers, subscriber];
        return this;
    }

    unsubscribe(type: string, subscriber: SubscriberWithId) {
        const subscribers: SubscriberWithId[] = EventBus.subscribersWithIds[type] || [];
        EventBus.subscribersWithIds[type] = subscribers.filter((s) => {
            return s.id !== subscriber.id;
        });
    }

    private logEmittedEvent(event: WhitelabelEvent) {
        console.log(`[EventBus] Emitting event type "${event.type}":`);
        const payload = (event as WhitelabelEventWithPayload<any>).payload || {};
        console.table({
            type: event.type,
            ...Object.entries(payload)
                .reduce((acc, [key, value]) => {
                    if (value instanceof HTMLElement) {
                        return ({...acc, [key]: `#${value.id}`});
                    }
                    return ({...acc, [key]: value});
                }, {})
        });
    }

    emit(event: WhitelabelEvent) {
        if (EventBus.isDebug) {
            this.logEmittedEvent(event);
        }
        const subscribers: SubscriberWithId[] = EventBus.subscribersWithIds[event.type] || [];
        for (const subscriber of subscribers) {
            subscriber.callback(event);
        }
    }

    emitMany(events: WhitelabelEvent[]) {
        events.forEach(e => this.emit(e));
    }

    public static getInstance(): EventBus {
        if (!(window as any).eventBusInstance) {
            (window as any).eventBusInstance = new EventBus();
        }
        return (window as any).eventBusInstance;
    }
}
