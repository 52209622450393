import GoogleTracking from "./GoogleTracking";

class GA4Tracking implements GoogleTracking {
    static trackEvent = (eventName: string, otherArguments = {}) => {
        // @ts-ignore
        if (window.isGA4Enabled) {
            // @ts-ignore
            gtag('event', eventName);
        }
    }

    trackEvent(name: string, otherArguments = {}): void {
        GA4Tracking.trackEvent(name, otherArguments)
    }
}

export default GA4Tracking;