export class LocalStorageWrapper {
    public setItem(key: string, value: string): void {
        try {
            localStorage && localStorage.setItem(key, value);
        } catch (e) {}
    }

    public getValue(key: string): string | null {
        try {
            return localStorage && localStorage.getItem(key);
        } catch (e) {
            return null;
        }
    }

    public getObject<T>(key: string): T {
        try {
            return localStorage && JSON.parse(localStorage.getItem(key)!!) as T;
        } catch (e) {
            return <T>null;
        }
    }
}